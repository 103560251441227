<template>
  <div>
    <div class="col-12 text-center text-primary text-h3 q-mt-xl">Comments</div>
    <div class="col-12 text-center text-grey-8 text-h5 q-mt-xl">Comming Soon...</div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>