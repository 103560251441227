<template>
  <div
    class="row q-mt-xl q-mx-sm q-pa-xl"
    style="background-color: #fcf5eb; min-height: 250px"
  >
    <div class="col-12 column items-center justify-center">
      <div :class="fontSize">
        {{ professor.fname }}, <b> {{ professor.lname }} </b>
      </div>
      <div class="text-body1 q-pb-md">{{ professor.subjects.join(", ") }}</div>
      <div class="text-body1 q-pb-md">
        <a :href="professor.url">Institute Website</a>
      </div>
      <!-- The current prof that is displayed is already in the global prof store under selected, so no further information has to be provided. -->
    </div>
    <div class="col-xs-12 col-sm-12 column justify-center items-center q-mt-md">
      <q-btn size="xl" padding="sm lg" color="blue-7" v-if="showRateButton">
        <router-link
          :to="`/professor/rate`"
          
          style="text-decoration: none; color: white"
          > {{$t('profcheck.rate')}} </router-link
        >
      </q-btn>
      <div class="text-body1 text-italic" v-if="!showRateButton"> {{$t('profcheck.login_to_rate')}} </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    professor: {
      type: Object,
      required: true,
    },
    showRateButton: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    fontSize() {
      return this.$q.platform.is.desktop
        ? "text-h2 text-blue-4"
        : " text-h4 text-blue-4";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.title {
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: #fcf5eb;
  margin-top: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile h1 {
  color: #5bbdf4;
  font-family: "Poppins", sans-serif;
  font-size: max(3.5vw, 1.8em);
}

.profile p {
  font-family: "Poppins", sans-serif;
  font-size: max(1vw, 0.8em);
}
</style>
