<template>
    <div class="row q-mt-xl q-pa-xl">
        <div class="col-12 text-center">
            <p class="text-body1 text-grey-8">5 = {{$t('profcheck.excellent')}} <br> 1 = {{$t('profcheck.poor')}} </p>
        </div>
        <div class="col-12 row justify-center q-col-gutter-x-md" v-if="this.ratingData">
            <div class="col-xs-12 col-md-4 column items-stretch">
                <div class="q-py-lg q-px-xl column items-center shadow-1" style="background-color: #fcf5eb">
                <div class="text-h1 text-blue-4 text-bold q-pt-sm"> {{ ratingData.averageStars.toFixed(1) }}</div>
                <span class="text-grey-8"> &#x00D8; Stars</span>
                <p class="text-grey-8">{{ ratingData.numRatings }} reviews</p>
                </div>
            </div>
            <div class="col-xs-12 col-md-8 row justify-stretch">
            <div class="col-xs-12 col-md-12 row justify-center shadow-1 q-py-lg">
                <div class="col-xs-6 col-md-4 column items-center q-pt-sm" v-for="(value, i) in ratingData.averageStarsPerCategory" v-bind:key="i">
                    <div :class="fontSize">{{ value.value.toFixed(1) }}</div>
                    <div class="text-body2 text-grey-8">{{ value.title }}</div>
                </div>
            </div>
            </div>
            <div class="col-12 text-center text-primary text-h3 q-mt-xl">Questions</div>
            <div class="col-12 row justify-center q-mt-md q-mb-xl">
                <div class="col-xs-12 col-sm-4 column items-center q-pa-lg border" v-for="(value, i) in ratingData.averageStarsPerCategory" v-bind:key="i">
                    <div :class="fontSizeRating">{{ value.title }}</div>
                    <p>{{ value.criteria }}</p>
                    <q-rating v-model="value.value" size="2em" :max="5" color="primary" readonly></q-rating>
                </div>
            </div>
        </div>
        <div class="col-12" v-else>
            <div class="col-12 column justify-center">
            <h4 style="text-align: center; color: #5bbdf4; margin-bottom: 0;">{{$t('profcheck.no_ratings')}}</h4>
            <h4 style="text-align: center; color: #5bbdf4; margin-top: 1em;">{{$t('profcheck.no_ratings_be_the_first')}}</h4>
            </div>
        </div>
    </div>
</template>

<script>
import { QSpinnerGears } from 'quasar';
import { ref } from 'vue';
import { useProfStore } from '../../store/prof.store';

export default {
    setup: () => {
        const profStore = useProfStore();

        return {
            profStore,
            ratingData: ref({
                averageStars: 0,
                numRatings: 0,
                averageStarsPerCategory: [],
            }),
        }
    },
    props: {
        professor: {
            type: Object,
            default: null,
        },
    },
    computed : {
        fontSize () {
            return this.$q.platform.is.desktop ? "text-h4 text-blue-4" : " text-h6 text-blue-4"
        },
        cardSize () {
            return this.$q.platform.is.desktop ? "q-py-md q-px-xl column items-center shadow-1" : "col-12 q-py-lg column items-center shadow-1" 
        },
        fontSizeRating () {
            return this.$q.platform.is.desktop ? "text-h5 text-grey-8" : "text-body1 text-grey-8"
        },
        fontSizeOverall () {
            return this.$q.platform.is.desktop ? "text-h1 text-blue-4 text-bold" : "text-h2 text-blue-4 text-bold"
        }
    },
    async created() {
        const notif = this.$q.notify({
            spinner: QSpinnerGears,
            timeout: 0,
            group: false,
            color: 'primary',
            message: `Loading rating statistics for ${this.professor.title ?? ''} ${this.professor.fname} ${this.professor.lname}...`,
            position: 'bottom',
        });

        try {
            const response = await this.profStore.getProfAvg(this.professor._id);
            console.log(`Rating Response for "${this.professor.title ? this.professor.title + ' ' : ''}${this.professor.fname} ${this.professor.lname}"`, response);

            if (response.status === 200) {
                notif({
                    message: 'Rating statistics loaded',
                    color: 'positive',
                    icon: 'check',
                    timeout: 1000,
                    spinner: false,
                });

                if (response.data.numRatings > 0) {
                    this.ratingData = response.data;
                } else {
                    this.ratingData = null;
                }
            } else {
                notif({
                    message: 'Failed to load rating statistics',
                    color: 'negative',
                    icon: 'close',
                    spinner: false,
                    timeout: 1000,
                });
            }
        } catch (e) {
            notif({
                message: 'Failed to load rating statistics. Please check the console for more information and notify the developers of this incidence.',
                color: 'negative',
                icon: 'close',
                spinner: false,
                timeout: 1000,
            });
        }


    }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display&display=swap');

.rating-sum {
    font-family: 'Big Shoulders Display', cursive;

}

.rating-sum p {
    color: #FBD7A1;
    font-family: "Sora", sans-serif;
}

.rating-all {
    display: grid;
    grid-gap: 30px;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(200px, 0.5fr));
    height: 100%;
    margin-left: 6%;
    margin-right: 6%;
    margin-top: 5%;
}

.rating-obj {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rating-obj h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 2px #5bbdf4;
    border-radius: 6.78px;
    color: #5bbdf4;
    font-family: "Sora", sans-serif;
    font-weight: bold;
    font-size: 1.8em;
    padding-top: 20%;
    padding-bottom: 20%;
    padding-right: 30%;
    padding-left: 30%;
}

.rating-obj p {
    color: #5bbdf4;
    font-family: "Sora", sans-serif;
    font-size: max(1.2em);
}

.rating-ques-const {
    display: grid;
    justify-content: space-between;
    grid-gap: 50px;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(500px, 0.5fr));
    height: 100%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10%
}

.rating-ques {
    font-family: "Sora", sans-serif;
}

.rating-ques h1 {
    color: #5bbdf4;
    font-size: max(2vw, 1.5em);
    font-weight: bold;
}

.rating-ques p {
    color: #555555;
}
.no-border {
    border-right: blue;
}
</style>