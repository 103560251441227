<template>
    <div>
        <ProfessorSearch />

        <div v-if="selected && selected._id">
            <ProfessorTile :professor="selected" :showRateButton="loggedIn" />
            <RatingSummary :professor="selected" />
            <Comments />
        </div>
        <div v-else style="width: fit-content; margin: auto; margin-top: 2em">
            <q-spinner color="primary" size="5em" :thickness="5" style="margin: auto"></q-spinner>
        </div>
    </div>
</template>

<script>
import ProfessorSearch from "@/components/profcheck/ProfessorSearch.vue";
import ProfessorTile from "../components/profcheck/ProfessorTile.vue";
import RatingSummary from "../components/profcheck/RatingSummary.vue";
import Comments from "../components/profcheck/Comments.vue"
import { useUserStore } from '@/store/user.store';
import { useProfStore } from "@/store/prof.store";
import { ref } from "vue";
import router from "../router";

export default {
    components: {
        ProfessorSearch,
        ProfessorTile,
        RatingSummary,
        Comments
    },
    setup: () => {
        const userStore = useUserStore();
        const profStore = useProfStore();

        if (profStore.selected) {
            return {
                selectedProfessor: ref({}),
                loggedIn: ref(userStore.loggedIn),
                selected: ref(profStore.selected)
            }
        } else {
            router.push('/professors');
        }
    }
}
</script>

